import React, { useEffect, useState } from "react";
import { Button, Card, Skeleton } from "antd";
import apiCaller from "utils/apiCaller";
import ReactLoading from "react-loading";
import moment from "moment";

const WorkflowScheduleHold = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [description, setDescription] = useState("");
  const [showProgressNextBtn,setShowProgressNextBtn] = useState(false)

  let content: any;
  useEffect(() => {
    function extractedEventDate(obj: any) {
      for (const key in obj) {
        if (Array.isArray(obj[key])) {
          if (moment(obj[key][0]).isValid()) {
            return obj[key][0];
          }
        } else if (moment(obj[key]).isValid()) {
          return obj[key];
        }
      }
    }
    async function checkIfEventDateArrived() {
      const workflowId = props.workflowId;
      const note = props.currentStep?.params.note;
      const resp = await apiCaller.get("/api/all-form-data", {
        params: {
          workflowId,
        },
      });
      if (resp.data) {
        const date = extractedEventDate(resp.data[0]);

        const parsedEventDate = moment(date);

        // Get the current date
        const currentDate = moment();

        if (parsedEventDate.isSameOrBefore(currentDate, "day")) {
          props.onScheduleHoldComplete();
        } else if (parsedEventDate.isAfter(currentDate)) {
          setDescription(note);
          setShowProgressNextBtn(false)
          setLoading(false);
        }
      }
    }
    checkIfEventDateArrived();
  }, []);

  if (loading) {
    content = (
      <Card bordered={false}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <span style={{ fontSize: 24 }}>Loading, please wait...</span>
          <ReactLoading type="spokes" color="#990000" />
        </div>
        <Skeleton active />
      </Card>
    );
  } else {
    content = (
        <>
      <Card title="Schedule Hold" style={{ marginBottom: "20px" }}>
        <h2>Important Note!!</h2>
        <h4>{description}</h4>
      </Card>
      <div style={{ marginTop: 20 }}>
        {props.prevButton}
        

      </div>
      <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop:"100px", padding: "10px"}}>
      {showProgressNextBtn?

        <Button
          style={{ float:"right", backgroundColor: "grey", color:"white" }}
          type="primary"
          onClick={() => {
            const userConfirmed = window.confirm("Are you sure you wish to proceed before the exam date?");
            if (userConfirmed) {
                props.onScheduleHoldComplete();
            }
          }}
        >
          {props.currentStep?.nextStepTitle !== ""
            ? `Immediately ${props.currentStep?.nextStepTitle.split('|')[0]}`
            : null}
        </Button>
        :
        <>
          <input
          type="checkbox"
          id="greyCheckbox"
          style={{ float:"right", width: "20px", height: "20px", accentColor: "grey" }}
          onClick={()=>setShowProgressNextBtn(true)}
        />
        <label htmlFor="greyCheckbox" style={{float:"right", marginLeft: "8px" }}>
          Override Wait
        </label>
        </>
        }
      </div>
    </>
    );
  }

  return <>{content}</>;
};

export default WorkflowScheduleHold;

import { Input, Button, Card, TreeSelect, TableProps, DatePicker } from "antd";
import { DownloadOutlined, FileSearchOutlined } from "@ant-design/icons";
import { useEffect, useState, useContext } from "react";
import { CSVLink } from "react-csv";
import apiCaller from "utils/apiCaller";
import OurTable from "./OurTable";
import '../../index.css';
import _ from "lodash";
import { TableItem } from "models";
import moment from "moment";
import type { FilterValue } from "antd/lib/table/interface";
import { AuthContext } from "context/AuthProvider";
const { Search } = Input;
const { RangePicker } = DatePicker;

interface Props {
	params: {
		target: string;
		workflowFile: string;
		colsByStep: Record<string, string[]>;
		showCols: string[];
		searchCols: string[];
		semesters: string[];
		googleSheetName: string;
	};
}

interface Column {
	title: string;
	dataIndex: string;
	key: string;
}

const Aggregate = ({ params }: Props) => {
	const {
		target,
		workflowFile,
		colsByStep,
		showCols,
		searchCols,
		semesters,
		googleSheetName
	} = params;
	const [loading, setLoading] = useState<boolean>(false);
	const [columns, setColumns] = useState<Column[]>();
	const [data, setData] = useState<any[]>([]);

	const [searchText, setSearchText] = useState<string>("");
	const [filteredData, setFilteredData] = useState<any[]>([]);
	const [showColumns, setShowColumns] = useState(showCols);
	const [showColFilters, setShowColFilters] = useState<string[]>(showCols);
	const allColumns = Object.values(colsByStep).flat();

	const [filters, setFilters] = useState<Record<string, FilterValue | null>>({})
	const [semesterFilters, setSemesterFilters] = useState<string[]>([]);
	const [sorter, setSorter] = useState<any | undefined>(undefined);

	const [googleSheetLoading, setGoogleSheetLoading] = useState<boolean>(false);
	const { currentUser } = useContext(AuthContext);

	const colNameToDataIndex = (colName: string): string => {
		return colName.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
	};

	const dataIndexes = searchCols?.filter((col) => showColumns.includes(col)).map((col) => colNameToDataIndex(col));
	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchText(e.target.value);
	};

	const createTreeSelectOptions = (columns: string[]) => {
		const options = columns.map((col) => ({
			title: `${col}`,
			value: `${col}`,
		}));

		options.unshift({
			title: "Select All",
			value: "Select All",
		});

		return options;
	};
	const handleColSelect = (selectedValues: string[]) => {
		const isSelectAllSelected = selectedValues.includes("Select All");
		if (isSelectAllSelected) {
			setShowColFilters(["Select All", ...allColumns]);
			setShowColumns(allColumns);
		} else {
			if (showColFilters.includes("Select All")) {
				setShowColFilters([]);
				setShowColumns([]);
			} else {
				setShowColFilters(selectedValues);
				setShowColumns(selectedValues.filter((value) => value !== "Select All"));
			}
		}
	};
	const handleTableChange: TableProps<TableItem>["onChange"] = (pagination, filters, sorter, extra) => {
		setFilters(filters);
		setSorter(sorter);
	}
	const handleSemesterSelect = (selectedSemesters: string[]) => {
		const isSelectAllSelected = selectedSemesters.includes("Select All");
		// console.log(selectedSemesters);
		if (isSelectAllSelected) {
			setSemesterFilters(["Select All", ...semesters]);
		} else {
			if (semesterFilters.includes("Select All")) {
				setSemesterFilters([]);
			} else {
				setSemesterFilters(selectedSemesters);
			}
		}
	}

	const openGoogleSheet = async () => {
		// window.open(googleSheetUrl, "_blank");
		setGoogleSheetLoading(true);
		try {
			const cols = showColumns.map((col) => colNameToDataIndex(col));
			const formattedData = filteredData.map((item) =>
				cols.reduce((acc: any[], col) => {
					switch (col) {
						case "createdAt":
							acc.push(new Date(item[col]._seconds * 1000).toLocaleDateString());
							break;
						case "examDate":
							acc.push(new Date(item[col][0]).toLocaleDateString());
							break;
						default:
							acc.push(item[col]);
							break;
					}
					return acc;
				}, [])
			);
			const sheetData = [showCols, ...formattedData];
			// console.log(sheetData);
			// console.log(filteredData);
			const res = await apiCaller.post("/api/view-in-google-sheet", {
				userEmail: currentUser.email,
				netId: currentUser.netId,
				googleSheetName: googleSheetName,
				sheetData: sheetData,
			});

			const sheetId = res.data;

			if (sheetId) {
				const url = `https://docs.google.com/spreadsheets/d/${sheetId}/edit?usp=sharing`;
				window.open(url, sheetId);
			} else {
				console.error("Failed to get sheet ID");
			}
		} catch (error) {
			console.error("Error opening Google Sheet:", error);
		} finally {
			setGoogleSheetLoading(false);
		}

	}

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const stepFields = Object.fromEntries(
				Object.entries(colsByStep).map(([step, words]) => {
					const processedWords = words.map(colNameToDataIndex);
					return [step, processedWords];
				})
			);
			try {
				const result = await apiCaller.get("/api/aggregate", { params: { target, workflowFile, stepFields } });
				const fetchedData = result.data;
				if (fetchedData) {
					const dataWithKeys = fetchedData.map((item: any, index: number) => ({
						...item,
						key: `row-${index}-${new Date().getTime()}`,
					}));
					// console.log(dataWithKeys);

					setData(dataWithKeys);
					setFilteredData(dataWithKeys);
				}
			} catch (error) {
				console.error("Error fetching data:", error);
				// Optionally, handle errors here (e.g., set an error state)
			}
			setLoading(false);
		}

		fetchData();
	}, [target, workflowFile, colsByStep]);

	const filterAllDataForSortingText = (data: any) => (formatter: any) =>
		data.map((item: any) => ({
			text: formatter(item),
			value: formatter(item),
		}));

	useEffect(() => {
		const tableCols = showColumns.map((col: string) => {
			const convertedCol = colNameToDataIndex(col);
			const defaultColumn = {
				title: col,
				dataIndex: convertedCol,
				key: convertedCol,
			};

			switch (col) {
				case "Committee Eval":
					return {
						...defaultColumn,
						render: (text: any) => {
							if (text) {
								return text;
							} else {
								return "In Progress";
							}
						},
					}
				case "Created At":
					return {
						...defaultColumn,
						render: (text: any) => {
							return new Date(text._seconds * 1000).toLocaleDateString();
						},
						sorter: (a: any, b: any) => a[convertedCol]._seconds - b[convertedCol]._seconds,
						filterDropdown: (props: {
							setSelectedKeys: (selectedKeys: any[]) => void;
							selectedKeys: any[];
							confirm: () => void;
							clearFilters?: () => void;
						}) => (
							<div style={{ padding: 8 }}>
								<RangePicker
									format="MM/DD/YYYY"
									value={props.selectedKeys[0] ? [moment(props.selectedKeys[0][0]), moment(props.selectedKeys[0][1])] : undefined}
									onChange={(dates) => {
										if (dates && dates[0] && dates[1]) {
											props.setSelectedKeys([[dates[0].toDate(), dates[1].toDate()]]);
										}
									}}
								/>
								<div style={{ marginTop: 8, textAlign: 'right' }}>
									<a
										onClick={() => {
											props.clearFilters?.();
											props.confirm();
										}}
										style={{ marginRight: 8 }}
									>
										Clear
									</a>
									<a
										onClick={() => {
											props.confirm();
										}}
									>
										OK
									</a>
								</div>
							</div>
						),
						filterIcon: (filtered: boolean) => (
							<span
								style={{
									color: filtered ? '#1890ff' : undefined,
								}}
							>
								📅
							</span>
						),
						onFilter: (value: any, record: any) => {
							const recordDate = new Date(record[convertedCol]._seconds * 1000);
							const [startDate, endDate] = value;

							return (
								(!startDate || (recordDate > startDate)) &&
								(!endDate || (recordDate < endDate))
							);
						}
					};
				case "Exam Date":
					return {
						...defaultColumn,
						render: (text: any) => {
							return new Date(text[0]).toLocaleDateString();
						},
						sorter: (a: any, b: any) => a[convertedCol]._seconds - b[convertedCol]._seconds,
						filterDropdown: (props: {
							setSelectedKeys: (selectedKeys: any[]) => void;
							selectedKeys: any[];
							confirm: () => void;
							clearFilters?: () => void;
						}) => (
							<div style={{ padding: 8 }}>
								<RangePicker
									format="MM/DD/YYYY"
									value={props.selectedKeys[0] ? [moment(props.selectedKeys[0][0]), moment(props.selectedKeys[0][1])] : undefined}
									onChange={(dates) => {
										if (dates && dates[0] && dates[1]) {
											props.setSelectedKeys([[dates[0].toDate(), dates[1].toDate()]]);
										}
									}}
								/>
								<div style={{ marginTop: 8, textAlign: 'right' }}>
									<a
										onClick={() => {
											props.clearFilters?.();
											props.confirm();
										}}
										style={{ marginRight: 8 }}
									>
										Clear
									</a>
									<a
										onClick={() => {
											props.confirm();
										}}
									>
										OK
									</a>
								</div>
							</div>
						),
						filterIcon: (filtered: boolean) => (
							<span
								style={{
									color: filtered ? '#1890ff' : undefined,
								}}
							>
								📅
							</span>
						),
						onFilter: (value: any, record: any) => {
							const recordDate = new Date(record[convertedCol]._seconds * 1000);
							const [startDate, endDate] = value;

							return (
								(!startDate || (recordDate > startDate)) &&
								(!endDate || (recordDate < endDate))
							);
						}
					}
				case "Name":
					return {
						...defaultColumn,
						sorter: (a: any, b: any) => a[convertedCol].localeCompare(b[convertedCol]),
					}
				case "Semester Season":
					return {
						...defaultColumn,
						filters: _.uniqWith(
							filterAllDataForSortingText(data)((item: any) => item[convertedCol]),
							_.isEqual
						),
						onFilter: (text: any, record: any) => record[convertedCol] === text,
						sorter: (a: any, b: any) => a[convertedCol].localeCompare(b[convertedCol]),
					}
				case "Semester Year":
					return {
						...defaultColumn,
						filters: _.uniqWith(
							filterAllDataForSortingText(data)((item: any) => item[convertedCol]),
							_.isEqual
						),
						onFilter: (text: any, record: any) => record[convertedCol] === text,
						sorter: (a: any, b: any) => a[convertedCol].localeCompare(b[convertedCol]),
					}
				default:
					return {
						...defaultColumn,
					};
			}
		});
		setColumns(tableCols);
	}, [showColumns, data]);

	useEffect(() => {
		let newFilteredData = data;
		// filtered by search
		if (searchText) {
			const searchValue = searchText.toLowerCase();
			newFilteredData = newFilteredData.filter((row: any) =>
				Object.keys(row).some((key) => {
					if (dataIndexes && dataIndexes.includes(key)) {
						const value = row[key];
						if (typeof value === "string" || typeof value === "number") {
							return `${value}`.toLowerCase().includes(searchValue);
						}
					}
					return false;
				})
			);
		}
		// filtered by semester
		if (semesterFilters.length > 0) {
			const filteredBySemester = [] as string[];
			semesterFilters.filter((semester) => semester !== "Select All")
				.forEach((semester) => {
					const [season, year] = semester.split(" ");
					newFilteredData.forEach((row) => {
						if (row["semesterSeason"] === season &&
							row["semesterYear"] === year) {
							filteredBySemester.push(row);
						}
					}
					)
				});
			newFilteredData = filteredBySemester;
		}
		// filtered by other filters from the table
		Object.keys(filters).forEach((column) => {
			const filterValues = filters[column];
			if (filterValues) {
				switch (typeof filterValues[0]) {
					case "string":
						const filterSet = new Set(filterValues);
						newFilteredData = newFilteredData.filter((row) => filterSet.has(row[column]));
						break;
					case "object":
						const [startDate, endDate] = Array.isArray(filterValues[0]) ? filterValues[0] : [undefined, undefined];
						newFilteredData = newFilteredData.filter((row) => {
							const recordDate = new Date(row[column]._seconds * 1000);
							return (
								(!startDate || recordDate > startDate) &&
								(!endDate || recordDate < endDate)
							);
						});
				}
			}
		});

		if (sorter && sorter.column) {
			const isAscend = sorter.order === 'ascend';
			newFilteredData = newFilteredData.sort((a, b) => {
				return isAscend ?
					sorter.column.sorter(a, b) :
					sorter.column.sorter(b, a);
			})
		}

		setFilteredData(newFilteredData);
	}, [filters, searchText, semesterFilters, sorter]);

	return (
		<>
			<div style={{ textAlign: "center" }}>
				<div className="search-section">
					<div className="sub-search-section">
						{searchCols && (
							<Search
								placeholder={`Search By ${searchCols.join(" / ")}`}
								onChange={handleSearch}
								allowClear={true}
								value={searchText}
							/>
						)}
					</div>

					<div className="sub-search-section">
						{showCols && (
							<TreeSelect
								treeData={createTreeSelectOptions(allColumns)}
								value={showColFilters}
								onChange={handleColSelect}
								treeCheckable={true}
								placeholder="Select Columns to Display"
								style={{ width: "100%" }}
								defaultValue={showColumns}
								maxTagCount={2}
								maxTagPlaceholder={(omittedValues) => `+${omittedValues.length} more`}
							/>
						)}
					</div>
				</div>

				<div className="search-section">
					{semesters && (
						<TreeSelect
							treeData={createTreeSelectOptions(semesters)}
							value={semesterFilters}
							onChange={handleSemesterSelect}
							treeCheckable={true}
							placeholder="Select Semesters"
							style={{ width: "100%" }}
							maxTagCount={2}
							maxTagPlaceholder={(omittedValues) => `+${omittedValues.length} more`}
						/>
					)}
				</div>

				<Card bordered={false} bodyStyle={{ padding: "0" }}>
					<div id="container" style={{ display: "flex", justifyContent: "space-between", margin: "0 0px" }}>
						{googleSheetName &&
							<Button
								type="primary"
								style={{ alignItems: 'center' }}
								shape="round"
								icon={<FileSearchOutlined />}
								onClick={openGoogleSheet}
								loading={googleSheetLoading}
								disabled={googleSheetLoading}
							>
								View in Google Sheets
							</Button>
						}

						<Button
							type="primary"
							style={{ alignItems: "center" }}
							shape="round"
							icon={<DownloadOutlined />}
						>
							<CSVLink
								style={{ color: "white", width: "100px" }}
								data={filteredData.map((row: any) => {
									const { key, ...rest } = row;
									return {
										...rest,
										createdAt: row.createdAt ? new Date(row.createdAt._seconds * 1000).toLocaleDateString() : "",
									};
								})}
								filename={`${target}.csv`}
								headers={columns?.map((col: any) => ({
									label: col.title,
									key: `${col.dataIndex}`,
								}))}
							>
								Download Current Filtered Data As CSV
							</CSVLink>
						</Button>
					</div>

					<div style={{ marginTop: "10px", marginLeft: "0px" }}>
						<OurTable
							columns={columns}
							data={filteredData}
							isLoading={loading}
							onChange={handleTableChange}
						/>
					</div>
				</Card >
			</div >
		</>
	);
}

export default Aggregate;

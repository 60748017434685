import { useState, useEffect } from "react";
import { Table } from "antd";
import ListView from "./ListView";
import { DASHBOARD_FILTERS_KEY } from "../../views/DashboardView";

const OurTable = ({ className, data, columns, actionButton, rowSelection, onChange, expandable, isLoading }) => {
  const storedFilters = JSON.parse(localStorage.getItem(DASHBOARD_FILTERS_KEY));
  const defaultPageSize = storedFilters && storedFilters.pageSize !== 0 ? storedFilters.pageSize : 10;
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pageNumber, setPageNumber] = useState(1);

  // Event handler for pagination change
  const handlePaginationChange = (pageNumber, newPageSize) => {
    setPageSize(newPageSize);
    setPageNumber(pageNumber);
    if (storedFilters) {
      storedFilters.pageSize = newPageSize;
      localStorage.setItem(DASHBOARD_FILTERS_KEY, JSON.stringify(storedFilters));
    }
  };

  // Effect to update pageSize when storedFilters change
  useEffect(() => {
    setPageSize(defaultPageSize);
    // setPageNumber(1);
  }, [storedFilters]);

  const isMobileView = window.innerWidth < 992;


  return (
    <div>
      {isMobileView ? (
        <ListView
          data={data}
          mobColumns={columns}
          pageSize={pageSize}
          actionButton={actionButton}
          rowSelection={rowSelection}
        />
      ) : (
        <Table
          className={className}
          dataSource={data}
          columns={columns}
          rowKey="key"
          rowSelection={rowSelection}
          onChange={typeof onChange === "function" ? onChange : () => {}}
          expandable={expandable}
          pagination={{
            current: pageNumber,
            pageSize: pageSize,
            total: data.length,
            showSizeChanger: true,
            pageSizeOptions: ["10", "15", "20"],
            onChange: handlePaginationChange,
            onShowSizeChange: handlePaginationChange,
          }}
          scroll={{ x: "auto" }}
		  loading={isLoading}
        />
      )}
    </div>
  );
};

export default OurTable;

OurTable.defaultProps = {
  className: null,
  actionButton: {},
  rowSelection: null,
  onChange: {},
  expandable: {},
  isLoading: false,
};
